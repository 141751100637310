import React from 'react';
import { Button, ButtonProps } from 'reactstrap';
import classNames from 'classnames';

export const FymButton: React.FC<
  React.PropsWithChildren<{
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    block?: boolean;
    outline?: boolean;
    href?: string;
    className?: string;
    target?: ButtonProps['target'];
    rel?: ButtonProps['rel'];
    inverse?: boolean;
    type?: ButtonProps['type'];
    disabled?: ButtonProps['disabled'];
  }>
> = React.forwardRef(
  ({ type, onClick, outline, children, href, className, block, target, rel, inverse, disabled }, ref) => {
    return (
      <Button
        ref={ref as any}
        block={block}
        onClick={onClick}
        href={href}
        color="link"
        className={classNames('fym-button', { outline, inverse }, className)}
        target={target}
        rel={rel}
        type={type}
        disabled={disabled}
      >
        <div className="fym-button-bg" />
        <div className="fym-button-inner">{children}</div>
      </Button>
    );
  }
);
