import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { config } from '../../../../config/config';
import { useIsRunning } from './useIsRunning';
import { Timer } from './Timer';

const startDate = config.anniversary.startsAt.getTime();
const endDate = config.anniversary.endsAt.getTime();

export const AnniversaryHelloBar: React.FC = React.memo(() => {
  const intl = useIntl();
  const router = useRouter();
  const raw = (router.query.raw as string) === '1';

  const timeLeft = useIsRunning(startDate, endDate);
  if (!timeLeft.isRunning || raw) {
    return null;
  }
  return (
    <Container className="promo-bar promo-bar-bf py-3 py-md-0" fluid>
      <Row className="justify-content-center align-items-center h-100">
        <Col className="h-100">
          <Container fluid className="promo-bar-content h-100">
            <Row className="justify-content-center align-items-center  h-100">
              <Col>
                <div className="d-flex flex-column flex-md-row justify-content-center align-items-center h-100">
                  <div className="d-flex flex-column mx-0 mx-md-1 mx-lg-4">
                    <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                      <span>
                        {intl.formatMessage(
                          {
                            id: 'web.promo-bar.description',
                            defaultMessage: '{promoName} SALE'
                          },
                          {
                            promoName: intl
                              .formatMessage({
                                id: 'web.promo.anniversary',
                                defaultMessage: 'Anniversary'
                              })
                              .toUpperCase()
                          }
                        )}{' '}
                        <small className="text-gold m-2">
                          <i className="fa-solid fa-bolt" />
                        </small>{' '}
                      </span>
                      <span className="fw-bold">
                        {intl.formatMessage({
                          id: 'web.promo-bar.anniversary.free_transfers',
                          defaultMessage: 'FREE unlimited transfers'
                        })}{' '}
                        <small className="text-gold m-2">
                          <i className="fa-solid fa-bolt" />
                        </small>{' '}
                      </span>
                    </div>
                    <small className="opacity-80">
                      {intl.formatMessage({
                        id: 'web.promo-bar.just_download',
                        defaultMessage: 'Simply download the app and follow instructions'
                      })}
                    </small>
                  </div>
                  <div className="mx-0 mx-md-1 mx-lg-4">
                    <small>
                      {intl.formatMessage({
                        id: 'web.promo-bar.ends-at',
                        defaultMessage: 'Offer Ends In:'
                      })}
                    </small>
                    <Timer endDate={endDate} startDate={startDate} />
                  </div>
                  <Link prefetch={false} href="/download">
                    <Button size="lg" color="gold" className="my-2 mx-0 mx-md-1 mx-lg-4">
                      {intl.formatMessage({
                        id: 'web.promo-bar.download',
                        defaultMessage: 'Download'
                      })}
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
});
