import React from 'react';
import { NavbarBrand } from 'reactstrap';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import Link from 'next/link';
import FymSignLogoGradient from '../../public/statics/images/fym-sign-logo-gradient.svg';

const BrandLink: React.FC<
  React.PropsWithChildren<{
    href: string;
    className: string;
  }>
> = ({ href, children, className }) => (
  <Link prefetch={false} href={href} className={className}>
    {children}
  </Link>
);
export const GenericNavbarBrand: React.FC<{
  isCentered?: boolean;
  href?: string;
}> = ({ isCentered, href = '/' }) => {
  const intl = useIntl();

  return (
    <NavbarBrand
      className={classNames('mx-auto', {
        'me-xl-1': !isCentered,
        isCentered
      })}
      tag={BrandLink}
      href={href}
    >
      <FymSignLogoGradient
        alt={intl.formatMessage({
          id: 'web.nav.logo-alt',
          defaultMessage: 'FreeYourMusic – Playlist transfer for music streaming services'
        })}
        title={intl.formatMessage({
          id: 'web.nav.logo-alt',
          defaultMessage: 'FreeYourMusic – Playlist transfer for music streaming services'
        })}
      />
    </NavbarBrand>
  );
};
