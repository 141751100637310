import React from 'react';
import padStart from 'lodash/padStart';
import { useIntl } from 'react-intl';
import { TypedMemo } from '../../../../components/types';
import { useIsRunning } from './useIsRunning';

export const Timer: React.FC<{
  startDate: number;
  endDate: number;
}> = TypedMemo(({ startDate, endDate }) => {
  const intl = useIntl();
  const timeLeft = useIsRunning(startDate, endDate);
  return (
    <div className="timer-container mx-2 my-1 my-md-2">
      <div className="timer-container-box">
        <div className="timer-container-value">{padStart(timeLeft.d.toString(), 2, '0')}</div>
        <div className="timer-container-label">
          {intl.formatMessage({
            id: 'web.timer.days',
            defaultMessage: 'DAYS'
          })}
        </div>
      </div>
      <div className="timer-container-box">
        <div className="timer-container-value">{padStart(timeLeft.h.toString(), 2, '0')}</div>
        <div className="timer-container-label">
          {intl.formatMessage({
            id: 'web.timer.hours',
            defaultMessage: 'HRS'
          })}
        </div>
      </div>
      <div className="timer-container-box">
        <div className="timer-container-value">{padStart(timeLeft.m.toString(), 2, '0')}</div>
        <div className="timer-container-label">
          {intl.formatMessage({
            id: 'web.timer.minutes',
            defaultMessage: 'MINS'
          })}
        </div>
      </div>
      <div className="timer-container-box">
        <div className="timer-container-value">{padStart(timeLeft.s.toString(), 2, '0')}</div>
        <div className="timer-container-label">
          {intl.formatMessage({
            id: 'web.timer.seconds',
            defaultMessage: 'SECS'
          })}
        </div>
      </div>
    </div>
  );
});
