import { MessageDescriptor } from 'react-intl';
import React from 'react';

export type TranslatableMessage = Required<Omit<MessageDescriptor, 'description'>>;

export enum DeviceType {
  Android = 'android',
  iOS = 'ios',
  Windows = 'windows',
  OSX = 'osx',
  Linux = 'linux'
}

export const TypedMemo: <T>(c: T, propsAreEqual?: Parameters<typeof React.memo>[1]) => T = React.memo;
