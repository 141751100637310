import { useCallback, useEffect, useMemo, useState } from 'react';

function getSecondsLeft(endDate: number) {
  return (endDate - new Date().getTime()) / 1000;
}

export function useIsRunning(startDate: number, endDate: number) {
  const [secondsLeft, setSecondsLeft] = useState<number>(getSecondsLeft(endDate));
  const onTick = useCallback(() => {
    setSecondsLeft(getSecondsLeft(endDate));
  }, [endDate]);
  useEffect(() => {
    const timer = setInterval(onTick, 1000);
    return () => {
      clearInterval(timer);
    };
  });
  return useMemo(() => {
    const hasStarted = startDate < new Date().getTime();
    const days = Math.floor(secondsLeft / (60 * 60 * 24));

    const divisorForHours = secondsLeft % (60 * 60 * 24);
    const hours = Math.floor(divisorForHours / (60 * 60));

    const divisorForMinutes = divisorForHours % (60 * 60);
    const minutes = Math.floor(divisorForMinutes / 60);

    const divisorForSeconds = divisorForMinutes % 60;
    const seconds = Math.ceil(divisorForSeconds);

    return {
      d: days,
      h: hours,
      m: minutes,
      s: seconds,
      isRunning: hasStarted && secondsLeft >= 0
    };
  }, [secondsLeft, startDate]);
}
