import { defineMessages } from 'react-intl';
import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

const { whatIsDescription } = defineMessages({
  whatIsDescription: {
    id: 'web.music-service.what-is.apple-music',
    defaultMessage:
      'Apple Music is a streaming service with over 100 million songs, curated playlists, and exclusive content. With a premium subscription, you can enjoy seamless integration with Apple devices and high-quality audio.  '
  }
});
export const appleMusic: MusicService = {
  id: 'apple-music',
  shortName: 'Apple Music',
  name: 'Apple Music',
  isComingSoon: false,
  areStatsSupported: true,
  isAlbumTransferSupported: true,
  isSupported: true,
  color: '#B166CC',
  logo: {
    light: nextAsset('images/music-services/apple-light.svg'),
    dark: nextAsset('images/music-services/apple-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/apple-light-padding.svg'),
    dark: nextAsset('images/music-services/apple-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/apple-color-light.svg'),
    dark: nextAsset('images/music-services/apple-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/apple-color-light-padding.svg'),
    dark: nextAsset('images/music-services/apple-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/apple-color-dark.svg'),
  whatIsDescription
};
