import { defineMessages } from 'react-intl';
import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

const { whatIsDescription } = defineMessages({
  whatIsDescription: {
    id: 'web.music-service.what-is.spotify',
    defaultMessage:
      'Spotify is a music streaming service that offers over 100 million songs, thousands of podcasts, and audiobooks. You can use Spotify on your mobile, browser, or desktop app. You can create an account for free.'
  }
});
export const spotify: MusicService = {
  id: 'spotify',
  shortName: 'Spotify',
  name: 'Spotify',
  isComingSoon: false,
  areStatsSupported: true,
  isAlbumTransferSupported: true,
  isSupported: true,
  color: '#1DB954',
  logo: {
    light: nextAsset('images/music-services/spotify-light.svg'),
    dark: nextAsset('images/music-services/spotify-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/spotify-light-padding.svg'),
    dark: nextAsset('images/music-services/spotify-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/spotify-color-light.svg'),
    dark: nextAsset('images/music-services/spotify-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/spotify-color-light-padding.svg'),
    dark: nextAsset('images/music-services/spotify-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/spotify-dark.svg'),
  whatIsDescription
};
