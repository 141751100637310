import { defineMessages } from 'react-intl';
import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

const { whatIsDescription } = defineMessages({
  whatIsDescription: {
    id: 'web.music-service.what-is.amazon-music',
    defaultMessage:
      'Amazon Music has a 100 million song music library, offers personalized playlists, and is integrated with Alexa. You’ll find all the most popular songs and albums. Free and premium plans are available.'
  }
});
export const amazonMusic: MusicService = {
  id: 'amazon-music',
  shortName: 'Amazon Music',
  name: 'Amazon Music',
  isComingSoon: false,
  areStatsSupported: true,
  isAlbumTransferSupported: false,
  isSupported: true,
  color: '#0077C1',
  logo: {
    light: nextAsset('images/music-services/amazon-light.svg'),
    dark: nextAsset('images/music-services/amazon-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/amazon-light-padding.svg'),
    dark: nextAsset('images/music-services/amazon-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/amazon-color-light.svg'),
    dark: nextAsset('images/music-services/amazon-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/amazon-color-light-padding.svg'),
    dark: nextAsset('images/music-services/amazon-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/amazon-color-dark.svg'),
  whatIsDescription
};
