import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { get, set } from 'local-storage';

export const useLocalStorage: <S>(key: string, initialValue: S | (() => S)) => [S, Dispatch<SetStateAction<S>>] = (
  key,
  initialValue
) => {
  const [storedValue, setStoredValue] = useState(() => {
    return get<any>(key) ?? initialValue;
  });

  const setValue = (value: any) => {
    setStoredValue(value);
    set<any>(key, value);
  };

  useEffect(() => {
    const value = get<any>(key) ?? initialValue;
    setStoredValue(value);
  }, [key, initialValue, setStoredValue]);

  return [storedValue, setValue];
};
