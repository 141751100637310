import React, { Suspense } from 'react';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { useRouter } from 'next/router';
import { DropdownLangItem, LangItemFlag } from './DropdownLangItem';

export const LanguagePicker: React.FC<{
  onSelected?: () => void;
}> = React.memo(({ onSelected }) => {
  const { locale, locales } = useRouter();
  return (
    <Suspense fallback={null}>
      {locales && locales.length > 1 && (
        <UncontrolledDropdown>
          <DropdownToggle size="sm" color="link" className="lang-picker-preview">
            <LangItemFlag lang={locale as string} />
          </DropdownToggle>
          <DropdownMenu className="lang-picker">
            {locales.map((lang: string | undefined) => (
              <DropdownLangItem key={lang} lang={lang} active={lang === locale} onClick={onSelected} />
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
    </Suspense>
  );
});
