import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { LanguagePicker } from './LanguagePicker';
import FymLogoFullLight from '../../public/statics/logos/fym-logo-full-light.svg';
import MusicApiLogoLight from '../../public/statics/musicApi/logo-full-light.svg';
import { FooterLinks } from './FooterLinks';

export const SocialIcon: React.FC<{
  href: string;
  icon: string;
}> = ({ href, icon }) => {
  return (
    <a href={href} className="social-icon" target="_blank" rel="noopener noreferrer nofollow" aria-label={href}>
      <i className={`fa-brands fa-${icon}`} />
    </a>
  );
};

const LogoImage: React.FC = () => {
  return <FymLogoFullLight className="img-fluid" alt="FreeYourMusic.com" />;
};

export const Footer: React.FC = () => {
  const intl = useIntl();

  const router = useRouter();
  const raw = (router.query.raw as string) === '1';
  const companyLinks = useMemo(
    () => [
      {
        title: intl.formatMessage({
          id: 'web.footer.links.company.about',
          defaultMessage: 'About'
        }),
        href: '/about'
      },
      {
        title: intl.formatMessage({
          id: 'web.footer.links.company.pricing',
          defaultMessage: 'Pricing'
        }),
        href: '/pricing'
      },
      {
        title: intl.formatMessage({
          id: 'web.footer.blog',
          defaultMessage: 'Blog'
        }),
        href: '/blog'
      },
      {
        title: intl.formatMessage({
          id: 'web.footer.faq',
          defaultMessage: 'FAQ'
        }),
        href: 'https://support.freeyourmusic.com/helpdesk/',
        nofollow: true
      },
      {
        title: intl.formatMessage({
          id: 'web.footer.presskit',
          defaultMessage: 'Press Kit'
        }),
        href: '/press-kit'
      },
      {
        title: intl.formatMessage({
          id: 'web.footer.what-we-do.listening-stats',
          defaultMessage: 'Listening Stats'
        }),
        href: '/stats'
      },
      {
        title: intl.formatMessage({
          id: 'web.home.all-services.title',
          defaultMessage: 'All available services list'
        }),
        href: '/available-music-services'
      }
    ],
    [intl]
  );

  const trustAndLegalLinks = useMemo(
    () => [
      {
        title: intl.formatMessage({
          defaultMessage: 'Privacy Policy',
          id: 'web.footer.privacy-policy'
        }),
        href: '/privacy-policy'
      },
      {
        title: intl.formatMessage({
          defaultMessage: 'Refund Policy',
          id: 'web.footer.refund-policy'
        }),
        href: '/refund-policy'
      },
      {
        title: intl.formatMessage({
          defaultMessage: 'Terms of Service',
          id: 'web.footer.terms-of-service'
        }),
        href: '/terms-of-service'
      },
      {
        title: intl.formatMessage({
          defaultMessage: 'Imprint',
          id: 'web.footer.imprint'
        }),
        href: '/imprint'
      }
    ],
    [intl]
  );
  const supportLinks = useMemo(
    () => [
      {
        title: 'support@freeyourmusic.com',
        href: 'mailto:support@freeyourmusic.com'
      },
      {
        title: intl.formatMessage({
          id: 'web.footer.lost-licence-code',
          defaultMessage: 'Lost License Code'
        }),
        href: '/manage/restore-license-code'
      },
      {
        title: intl.formatMessage({
          id: 'web.footer.submit-ticket',
          defaultMessage: 'Submit an issue'
        }),
        href: 'https://support.freeyourmusic.com/helpdesk/',
        nofollow: true
      }
    ],
    [intl]
  );
  if (raw) {
    return null;
  }
  return (
    <>
      <div className="footer-waves" />
      <footer className="footer pb-5">
        <Container>
          <Row className="mb-3">
            <Col xs={6} sm={4} className="text-center mx-auto mb-2">
              <LogoImage />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={8} sm={4} className="text-center mx-auto mb-2">
              <Link
                href="https://musicapi.com/"
                className="powered-by d-flex align-items-center justify-content-center"
                rel="nofollow"
              >
                {intl.formatMessage({
                  id: 'web.powered-by',
                  defaultMessage: 'Powered by'
                })}
                <div style={{ width: 100 }}>
                  <MusicApiLogoLight />
                </div>
              </Link>
            </Col>
          </Row>
          <Row className="text-center text-sm-start">
            <Col xs={4} sm={4} className="pt-2">
              <FooterLinks
                title={intl.formatMessage({
                  id: 'web.footer.links.company.title',
                  defaultMessage: 'Company'
                })}
                links={companyLinks}
              />
            </Col>
            <Col xs={4} sm={4} className="pt-2">
              <FooterLinks
                title={intl.formatMessage({
                  id: 'web.footer.links.trust-and-legal.title',
                  defaultMessage: 'Trust & Legal'
                })}
                links={trustAndLegalLinks}
              />
            </Col>
            <Col xs={4} sm={4} className="pt-2">
              <FooterLinks
                title={intl.formatMessage({
                  id: 'web.footer.links.support.title',
                  defaultMessage: 'Support'
                })}
                links={supportLinks}
              />
            </Col>
          </Row>
          <Row className="align-items-center flex-column-reverse flex-md-row ">
            <Col xs={12} md={6} className="text-center text-md-start mt-3 mt-md-0">
              <span className="footer-copyrights">
                <FormattedMessage
                  id="web.footer.rights"
                  defaultMessage="©{date} FreeYourMusic.com"
                  values={{ date: new Date().getFullYear() }}
                />
              </span>
            </Col>
            <Col xs={12} md={6} className=" text-center text-md-end mb-3 mb-md-0">
              <SocialIcon href="https://twitter.com/freeyourmusic" icon="twitter" />
              <SocialIcon href="https://www.facebook.com/freeyourmusicapp" icon="facebook" />
              <SocialIcon href="https://instagram.com/freeyourmusic_com" icon="instagram" />
              <SocialIcon href="https://www.tiktok.com/@freeyourmusic_com" icon="tiktok" />
              <div className="d-inline-block">
                <LanguagePicker />
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};
