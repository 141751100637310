import React, { useCallback } from 'react';
import { Button, Col, Collapse, Container, Nav, Navbar, Row } from 'reactstrap';
import classNames from 'classnames';
import { GenericNavbarBrand } from './GenericNavbarBrand';

export const GenericNavbar: React.FC<{
  rightContent?: React.ReactNode;
  additionalLeftNavItems?: React.ReactNode;
  isOpen?: boolean;
  className?: string;
  isSimple?: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  brandHref?: string;
}> = React.memo(({ className, rightContent, additionalLeftNavItems = [], isOpen, setIsOpen, isSimple, brandHref }) => {
  const toggle = useCallback(() => {
    if (!setIsOpen) {
      return;
    }
    setIsOpen((open: boolean) => !open);
  }, [setIsOpen]);

  return (
    <Container>
      <Row>
        <Col>
          <Navbar expand="xl" className={classNames('pt-3 mainNavbar', className)}>
            {!isSimple && (
              <Button
                onClick={toggle}
                color="transparent"
                className="d-block d-xl-none navbar-toggle-custom"
                title="Menu"
                style={{ width: 33 }}
              >
                <i className="fa-light fa-bars" />
              </Button>
            )}
            <GenericNavbarBrand isCentered={isSimple} href={brandHref} />
            {!isSimple && (
              <Collapse isOpen={isOpen} navbar>
                <Nav navbar>{additionalLeftNavItems}</Nav>
                {rightContent}
              </Collapse>
            )}
          </Navbar>
        </Col>
      </Row>
    </Container>
  );
});
