import { defineMessages } from 'react-intl';
import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

const { whatIsDescription } = defineMessages({
  whatIsDescription: {
    id: 'web.music-service.what-is.tidal',
    defaultMessage:
      'TIDAL is a music streaming service focused on high-fidelity and lossless audio quality and hosts over 100 million tracks. Explore millions of songs, curated playlists, and exclusive content from artists with a premium subscription. Enjoy high-quality audio and support artist payouts with a premium subscription.'
  }
});
export const tidal: MusicService = {
  id: 'tidal',
  shortName: 'Tidal',
  name: 'Tidal',
  isComingSoon: false,
  areStatsSupported: true,
  isAlbumTransferSupported: true,
  isSupported: true,
  color: '#000000',
  textColor: '#00a6a6',
  logo: {
    light: nextAsset('images/music-services/tidal-light.svg'),
    dark: nextAsset('images/music-services/tidal-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/tidal-light-padding.svg'),
    dark: nextAsset('images/music-services/tidal-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/tidal-color-light.svg'),
    dark: nextAsset('images/music-services/tidal-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/tidal-color-light-padding.svg'),
    dark: nextAsset('images/music-services/tidal-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/tidal-dark.svg'),
  whatIsDescription
};
