import React from 'react';
import { DropdownItem } from 'reactstrap';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useIntl } from 'react-intl';

export const LangItemFlag: React.FC<{
  lang: string;
}> = ({ lang }) => {
  const intl = useIntl();
  let flagName: string;
  switch (lang) {
    case 'en':
      flagName = 'gb';
      break;
    case 'sv':
      flagName = 'se';
      break;
    case 'pt':
      flagName = 'br';
      break;
    default:
      flagName = lang;
      break;
  }

  return (
    <img
      src={`/statics/images/flags/4x3/${flagName}.svg`}
      alt={intl.formatMessage(
        {
          id: 'web.langpicker.flag-alt',
          defaultMessage: "{country_code}'s flag"
        },
        { country_code: flagName.toUpperCase }
      )}
    />
  );
};

export const DropdownLangItem: React.FC<{
  lang: string | undefined;
  active: boolean;
  onClick: React.HTMLProps<HTMLAnchorElement>['onClick'];
}> = React.memo(({ lang, active, onClick }) => {
  const router = useRouter();
  const { pathname, query } = router;

  if (!lang) {
    return null;
  }
  return (
    <Link
      prefetch={false}
      href={{
        pathname,
        query
      }}
      passHref
      locale={lang}
    >
      <DropdownItem active={active} onClick={onClick}>
        <LangItemFlag lang={lang} /> {lang.toUpperCase()}
      </DropdownItem>
    </Link>
  );
});
