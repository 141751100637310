import React from 'react';
import Link from 'next/link';

export interface FooterLink {
  title: string;
  href: string;
  nofollow?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export const FooterLinkElement: React.FC<FooterLink> = ({ title, href, onClick, nofollow }) => {
  return (
    <li className="footer-link">
      {href.startsWith('http') ? (
        <a onClick={onClick} href={href} className="py-1" rel={nofollow ? 'nofollow' : undefined}>
          {title}
        </a>
      ) : (
        <Link prefetch={false} href={href} onClick={onClick} className="py-1" rel={nofollow ? 'nofollow' : undefined}>
          {title}
        </Link>
      )}
    </li>
  );
};
export const FooterLinks: React.FC<{
  title: string;
  links: FooterLink[];
}> = ({ title, links }) => {
  return (
    <div className="footer-links">
      <div className="footer-links-title">{title}</div>
      <ul className="list-unstyled footer-links d-flex flex-column justify-content-center mflex-wrap">
        {links.map((link) => (
          <FooterLinkElement
            title={link.title}
            href={link.href}
            key={link.title}
            nofollow={link.nofollow}
            onClick={link.onClick}
          />
        ))}
      </ul>
    </div>
  );
};
