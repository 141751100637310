import { defineMessages } from 'react-intl';
import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

const { whatIsDescription } = defineMessages({
  whatIsDescription: {
    id: 'web.music-service.what-is.youtube-music',
    defaultMessage:
      'YouTube Music is a streaming service with access to over 100 million songs, music videos, and remixes. A premium subscription allows you to explore curated playlists, personalized recommendations, and background listening.'
  }
});
export const youtubeMusic: MusicService = {
  id: 'youtube-music',
  shortName: 'YouTube Music',
  name: 'YouTube Music',
  isComingSoon: false,
  areStatsSupported: true,
  isAlbumTransferSupported: true,
  isSupported: true,
  color: '#FF0000',
  logo: {
    light: nextAsset('images/music-services/youtube-music-light.svg'),
    dark: nextAsset('images/music-services/youtube-music-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/youtube-music-light-padding.svg'),
    dark: nextAsset('images/music-services/youtube-music-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/youtube-music-color-light.svg'),
    dark: nextAsset('images/music-services/youtube-music-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/youtube-music-color-light-padding.svg'),
    dark: nextAsset('images/music-services/youtube-music-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/youtube-music-color-dark.svg'),
  whatIsDescription
};
