import React, { useMemo } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { config } from '../../../../config/config';
import { Timer } from './Timer';
import { useIsRunning } from './useIsRunning';

const startDate = config.blackFriday.startsAt.getTime();
const endDate = config.blackFriday.endsAt.getTime();

export const BlackFridayHelloBar: React.FC = React.memo(() => {
  const intl = useIntl();
  const router = useRouter();

  const timeLeft = useIsRunning(startDate, endDate);
  const raw = (router.query.raw as string) === '1';
  const isPricing = useMemo(() => router.pathname.startsWith(`/pricing`), [router.pathname]);

  if (!timeLeft.isRunning || raw) {
    return null;
  }
  return (
    <Container className="promo-bar promo-bar-bf py-3 py-md-0" fluid>
      <Row className="justify-content-center align-items-center h-100">
        <Col className="h-100">
          <Container className="promo-bar-content h-100">
            <Row className="justify-content-center align-items-center  h-100">
              <Col>
                <div className="d-flex flex-column flex-md-row justify-content-center align-items-center h-100">
                  <span>
                    {intl.formatMessage(
                      {
                        id: 'web.promo-bar.description',
                        defaultMessage: '{promoName} SALE'
                      },
                      {
                        promoName: intl
                          .formatMessage({
                            id: 'web.promo.black-friday',
                            defaultMessage: 'Black Friday'
                          })
                          .toUpperCase()
                      }
                    )}{' '}
                    <small className="text-gold m-2">
                      <i className="fa-solid fa-bolt" />
                    </small>{' '}
                  </span>
                  <span className="fw-bold">
                    {intl.formatMessage(
                      {
                        id: 'web.promo-bar.discount-description',
                        defaultMessage: '{discountValue} Off On All Plans'
                      },
                      {
                        discountValue: `${config.blackFriday.discountValue.toUpperCase()}%`
                      }
                    )}{' '}
                    <small className="text-gold m-2">
                      <i className="fa-solid fa-bolt" />
                    </small>{' '}
                  </span>
                  <div>
                    <small>
                      {intl.formatMessage({
                        id: 'web.promo-bar.ends-at',
                        defaultMessage: 'Offer Ends In:'
                      })}
                    </small>
                    <Timer endDate={endDate} startDate={startDate} />
                  </div>
                  {!isPricing && (
                    <Link prefetch={false} href="/pricing">
                      <Button size="lg" color="gold" className="my-2 mx-2">
                        {intl.formatMessage(
                          {
                            id: 'web.promo-bar.discount-cta',
                            defaultMessage: 'Get {discountValue} Off'
                          },
                          {
                            discountValue: `${config.blackFriday.discountValue.toUpperCase()}%`
                          }
                        )}
                      </Button>
                    </Link>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
});
